<template src="./marketplace.html"></template>

<script>
import mixins from "../../../mixins/mixins.js";
import { slider, slideritem } from "vue-concise-slider"; // import slider components
// https://warpcgd.github.io/vue-concise-slider/
export default {
  mixins: [mixins],
  components: { slider, slideritem },
  data() {
    return {
      classCategorySelected: false,
      activeSidebarCategories: false,
      categorySelected: null,
      currentLi: null,
      showCategories: false,
      defaultIndex: "",
      window: {
        width: 0,
        height: 0,
      },
      showProductDetails: false,
      productSelected: null,
      productPhotos: [],
      someList: [
        {
          html: "",
          style: {
            background: "#FFFF",
          },
        },
      ],
      //Sliding configuration [obj]
      options: {
        effect: "nest",
        pagination: true,
        thresholdDistance: 0,
        thresholdTime: 0,
        grabCursor: true,
        speed: 0,
        loop: true,
      },
      partners: [],
      showWebVersion: false,
      showMobileVersion: false,
      queryString: null,
      products: [],
      productsCache: [],
      productsFound: [],
      classButtonGrid: false,
      classButtonList: false,
      lgCol: 4,
      currentx: 1,
      numPages: 0,
      pagination: false,
      numPagesCache: 0,
      categories: [],
    };
  },
  watch: {
    lgCol: function (newVal) {
      const vm = this;
      vm.lgCol = newVal;
    },
    products: function (newVal) {
      const vm = this;
      vm.products = newVal;
    },
    currentx: function () {
      const vm = this;
      vm.changePagination();
    },
  },
  created: function () {
    const vm = this;
    window.addEventListener("resize", vm.handleResize);
    vm.handleResize();
  },
  destroyed: function () {
    const vm = this;
    window.removeEventListener("resize", vm.handleResize);
  },
  mounted: function () {
    const vm = this;
    vm.init();
  },
  methods: {
    init() {
      const vm = this;
      vm.getCategories();
      vm.getPartners();
      vm.getProducts();
      if (vm.lgCol === 4) {
        vm.classButtonGrid = true;
      }
    },
    moreInfo(product) {
      const vm = this;
      vm.productSelected = null;
      vm.productSelected = product;
      vm.getSliderProductPhotos(product.id);
    },
    getSliderProductPhotos(idProduct) {
      const vm = this;
      vm.axios.defaults.headers.common["Authorization"] = vm.$session.get(
        "bearer"
      );
      vm.axios
        .get(vm.$store.state.filooServer + "marketplace/photos/" + idProduct, {
          headers: { Authorization: vm.$session.get("bearer") },
        })
        .then(function (response) {
          if (response.status === 200) {
            if (response.data) {
              vm.productPhotos = response.data.photos;
              if (vm.productPhotos.length > 0) {
                vm.someList = [];
                for (const p in vm.productPhotos) {
                  const slide = {
                    html: `data:image/png;base64,${vm.productPhotos[p]}`,
                    style: {
                      background: "#FFFF",
                    },
                  };
                  vm.someList.push(slide);
                }
                vm.options = {
                  effect: "nest",
                  pagination: true,
                  thresholdDistance: 0,
                  thresholdTime: 0,
                  grabCursor: true,
                  speed: 0,
                  loop: true,
                };
                vm.showProductDetails = true;
              } else {
                if (
                  vm.testNullUndefinedEmpty(vm.productSelected.photo) == false
                ) {
                  vm.options = {
                    effect: "nest",
                    pagination: false,
                    thresholdDistance: 0,
                    thresholdTime: 0,
                    grabCursor: false,
                    speed: 0,
                    loop: false,
                  };
                  vm.someList = [];
                  const slide = {
                    html: `data:image/png;base64,${vm.productSelected.photo}`,
                    style: {
                      background: "#FFFF",
                    },
                  };
                  vm.someList.push(slide);
                  vm.showProductDetails = true;
                } else {
                  vm.setOptionsWithoutPhoto();
                  vm.showProductDetails = true;
                }
              }
            }
          }
        })
        .catch(function () {
          if (vm.testNullUndefinedEmpty(vm.productSelected.photo) == false) {
            vm.someList = [];
            vm.options = {
              effect: "nest",
              pagination: false,
              thresholdDistance: 0,
              thresholdTime: 0,
              grabCursor: false,
              speed: 0,
              loop: false,
            };
            const slide = {
              html: `data:image/png;base64,${vm.productSelected.photo}`,
              style: {
                background: "#FFFF",
              },
            };
            vm.someList.push(slide);
            vm.showProductDetails = true;
          } else {
            vm.setOptionsWithoutPhoto();
          }
        });
    },
    setOptionsWithoutPhoto() {
      const vm = this;
      vm.options = {
        effect: "nest",
        pagination: false,
        thresholdDistance: 0,
        thresholdTime: 0,
        grabCursor: false,
        speed: 0,
        loop: false,
      };
      vm.someList = [
        {
          html: "",
          style: {
            background: "#FFFF",
          },
        },
      ];
    },
    handleResize() {
      const vm = this;
      vm.window.width = window.innerWidth;
      vm.window.height = window.innerHeight;
      if (vm.window.width < 768) {
        vm.showWebVersion = false;
        vm.showMobileVersion = true;
      } else {
        vm.showWebVersion = true;
        vm.showMobileVersion = false;
      }
    },
    getCategories() {
      const vm = this;
      vm.axios
        .get(vm.$store.state.filooServer + "marketplace/categorias", {
          headers: { Authorization: vm.$session.get("bearer") },
        })
        .then(function (response) {
          if (response.status === 200) {
            if (response.data.content.length > 0) {
              vm.categories = response.data.content;
              vm.showCategories = true;
            }
          }
        })
        .catch(function (error) {
          console.warn(error);
        });
    },
    getPartners() {
      const vm = this;
      vm.axios
        .get(vm.$store.state.filooServer + "marketplace/parceiros", {
          headers: { Authorization: vm.$session.get("bearer") },
        })
        .then(function (response) {
          if (response.status === 200) {
            vm.partners = response.data.content;
          }
        })
        .catch(function (error) {
          console.warn(error);
        });
    },
    getProductsQueryString() {
      const vm = this;
      if (vm.testNullUndefinedEmpty(vm.queryString) === false) {
        const productsFound = vm.productsCache.filter((p) =>
          p.name.toUpperCase().includes(vm.queryString.toUpperCase())
        );
        if (productsFound.length > 0) {
          vm.productsFound = productsFound;
          vm.currentx = 1;
          vm.numPages = Math.ceil(productsFound.length / 6);
          const paginatedProducts = vm.listItems(productsFound, vm.currentx, 6);
          vm.products = paginatedProducts;
        } else {
          vm.products = [];
          vm.numPages = 0;
        }
      } else {
        vm.currentx = 1;
        vm.products = vm.listItems(vm.productsCache, vm.currentx, 6);
        vm.numPages = Math.ceil(vm.productsCache.length / 6);
      }
    },
    listItems(items, pageActual, limitItems) {
      const result = [];
      const totalPage = Math.ceil(items.length / limitItems);
      let count = pageActual * limitItems - limitItems;
      const delimiter = count + limitItems;

      if (pageActual <= totalPage) {
        for (let i = count; i < delimiter; i++) {
          if (items[i] != null) {
            result.push(items[i]);
          }
          count++;
        }
      }
      return result;
    },
    changePagination() {
      const vm = this;
      if (vm.productsCache.length > 0) {
        if (
          vm.testNullUndefinedEmpty(vm.queryString) == false ||
          vm.categorySelected !== null
        ) {
          const productsFound = vm.listItems(vm.productsFound, vm.currentx, 6);
          vm.products = productsFound;
        } else {
          vm.products = vm.listItems(vm.productsCache, vm.currentx, 6);
          vm.numPages = vm.numPagesCache;
        }
      }
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    filterByCategory(category, index) {
      const vm = this;
      vm.currentLi = index;
      vm.queryString = null;
      vm.categorySelected = category;
      const productsFound = vm.productsCache.filter(
        (p) => p.categoryId === category.id
      );
      vm.productsFound = productsFound;
      vm.currentx = 1;
      const paginatedProducts = vm.listItems(productsFound, vm.currentx, 6);
      vm.numPages = Math.ceil(productsFound.length / 6);
      vm.products = paginatedProducts;
    },
    getProducts() {
      const vm = this;
      vm.$vs.loading();
      vm.axios
        .get(vm.$store.state.filooServer + "marketplace/produtos?search=&size=10000&sort=id,desc", {
          headers: { Authorization: vm.$session.get("bearer") },
        })
        .then(function (response) {
          if (response.status === 200) {
            vm.productsCache = response.data.content;
            for (const p in vm.productsCache) {
              if (vm.testNullUndefinedEmpty(vm.productsCache[p].photo)) {
                vm.productsCache[p].imageData =
                  "../../../assets/images/pages/vuesax-login-bg.jpg";
              } else {
                vm.productsCache[
                  p
                ].imageData = `data:image/png;base64,${vm.productsCache[p].photo}`;
              }
            }
            vm.products = vm.listItems(vm.productsCache, vm.currentx, 6);
            vm.numPages = Math.ceil(vm.productsCache.length / 6);
            vm.pagination = vm.numPages > 1;
            vm.numPagesCache = vm.numPages;
            vm.$vs.loading.close();
          }
        })
        .catch(function (error) {
          vm.$vs.loading.close();
          console.warn(error);
        });
    },
    refreshList() {
      const vm = this;
      vm.products = [];
      vm.queryString = null;
      vm.numPages = 0;
      vm.currentx = 1;
      vm.currentLi = null;
      vm.categorySelected = null;
      vm.products = vm.listItems(vm.productsCache, vm.currentx, 6);
      vm.numPages = Math.ceil(vm.productsCache.length / 6);
    },
  },
};
</script>

<style lang="scss" src="./marketplace.scss">
</style>
